var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"7C5vmkFS0bt8vxgmrBOkb"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { init as SentryInit } from '@sentry/nextjs';
import { captureConsoleIntegration } from '@sentry/integrations';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

SentryInit({
  dsn: SENTRY_DSN || 'https://a713653538154b94a5ce4963bfba1979@o1160911.ingest.sentry.io/6474282',
  // Adjust this value in production, or use tracesSampler for greater control
  enabled: process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' && process.env.NODE_ENV === 'production',
  ignoreTransactions: ['/api/healthcheck'],
  integrations: [
    captureConsoleIntegration({
      levels: ['error'],
    }),
  ],
  sampleRate: 0.1, // 10% for all events

  tracesSampler: (samplingContext) => { // For transaction sample rate
    const { transactionContext } = samplingContext;
    const { name } = transactionContext;

    // Define custom sampling rates for specific routes
    if (name.includes('/order/manage/pendingcod')) {
      return 1.0;
    }

    if (name === '/') {
      return 0.8;
    }

    if (name.includes('/search')) {
      return 1.0;
    }

    if (name.includes('/product')) {
      return 0.6;
    }

    if (name.includes('/video-feed')) {
      return 0.7;
    }

    if (name.includes('/link-shopify')) {
      return 1.0;
    }

    if (name.includes('/dashboard/analytics')) {
      return 0.3;
    }

    if (name.includes('/manage-ndr')) {
      return 0.4;
    }

    if (name.includes('/manage-products/pushedToShopify')) {
      return 1.0;
    }

    if (name.includes('/payments')) {
      return 0.3;
    }

    if (name.includes('/login')) {
      return 1.0;
    }

    if (name.includes('/staff-login')) {
      return 1.0;
    }

    if (name.includes('/set-password')) {
      return 1.0;
    }

    if (name.includes('/activate/[link]')) {
      return 1.0;
    }

    // Default sampling rate
    return 0.1; // 10% for all other transactions
  },
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
